
/* IMPORTAÇÕES */

let date = new Date();
let v = date.getHours()+'-'+date.getMinutes()+'-'+date.getSeconds();

import Parallax from './components/Parallax.js';
import Templates from './components/Templates.js';
import SwiperPlugin from './components/SwiperPlugin.js';
// import TabsDestaque from './components/TabsDestaque.js';

/* INSTANCIA CLASSES */

const parallax      = new Parallax;
const templates     = new Templates;
const swiperplugin  = new SwiperPlugin;
// const tabsdestaque  = new TabsDestaque;

/* RUN EVENTOS HABILITANDO JQUERY */

$(function()
{
    parallax.init();
    templates.init();
    swiperplugin.init();
    // tabsdestaque.init();

    window.addEventListener('load', function()
    {
        // $('.tabComponente').addClass('Opened');
        // $('.wrapCarrosselContainer').addClass('Opened');
    });
});